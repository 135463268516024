<template>
  <v-card flat>
    <div class="filters">
      <help class="help--crud" :helpId="29" />
      <h2 class="text-center">
        Evaluaciones asignadas
        <v-tooltip top v-if="!franchiseDisabled">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              dark
              class="mb-2"
              style="float: right"
              @click.prevent="handleCreate()"
              >Asignar
            </v-btn>
          </template>
          <span>Asignar evaluación</span>
        </v-tooltip>
      </h2>
      <label><strong>Filtrar por:</strong> </label>
      <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
        <v-row class="mx-0" style="max-width: calc(100% - 0px)">
          <v-col cols="12" lg="4" md="4" sm="6" class="my-1 d-flex justify-center">
            <v-text-field hide-details="auto" label="Búsqueda" v-model="filters.search" />
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6" class="my-1 d-flex justify-center">
            <v-text-field
              hide-details="auto"
              format="dd-mm-yyy"
              label="Fecha de inicio"
              v-model="filters.startAt"
              type="date"
            />
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6" class="my-1 d-flex justify-center">
            <v-text-field
              hide-details="auto"
              format="dd-mm-yyy"
              label="Fecha final"
              v-model="filters.endAt"
              type="date"
            />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" v-if="!franchiseDisabled">
            <v-row no-gutters>
              <span>Empresa: </span>
            </v-row>
            <v-row no-gutters>
              <multiselect
                track-by="id"
                label="name"
                deselect-label="Remover"
                select-label="Seleccionar"
                placeholder="Seleccionar"
                v-model="filters.company"
                :options="companies"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                @search-change="getCompanies"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6">
            <v-row no-gutters>
              <span>Evaluación: </span>
            </v-row>
            <v-row no-gutters>
              <multiselect
                track-by="id"
                label="name"
                deselect-label="Remover"
                select-label="Seleccionar"
                placeholder="Seleccionar"
                v-model="filters.evaluation"
                :options="evaluations"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                @search-change="getEvaluations"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6">
            <v-row no-gutters> <span>Evaluador: </span> </v-row
            ><v-row no-gutters>
              <multiselect
                track-by="id"
                label="name"
                :custom-label="fullName"
                deselect-label="Remover"
                select-label="Seleccionar"
                placeholder="Seleccionar"
                v-model="filters.user"
                :options="users"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                @search-change="getUsers"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" v-if="!franchiseDisabled">
            <v-row no-gutters>
              <span>Estatus: </span>
            </v-row>
            <v-row no-gutters>
              <multiselect
                track-by="id"
                label="name"
                deselect-label="Remover"
                select-label="Seleccionar"
                placeholder="Seleccionar"
                v-model="filters.status"
                :options="statusOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              />
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="6"
            v-if="filters.status && filters.status.id === 3"
          >
            <v-row no-gutters>
              <span>Condición: </span>
            </v-row>
            <v-row no-gutters>
              <multiselect
                track-by="id"
                label="name"
                deselect-label="Remover"
                select-label="Seleccionar"
                placeholder="Seleccionar"
                v-model="filters.condition"
                :options="conditionOptions"
                :multiple="false"
                :allow-empty="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              />
            </v-row>
          </v-col>
          <!-- <v-col cols="12" lg="4" md="4" sm="6">
            <v-row no-gutters> <span>Periodicidad: </span> </v-row
            ><v-row no-gutters>
              <multiselect
                track-by="id"
                label="name"
                deselect-label="Remover"
                select-label="Seleccionar"
                placeholder="Seleccionar"
                v-model="filters.periodicity"
                :options="itemsPeriodicity"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              />
            </v-row>
          </v-col> -->
        </v-row>
        <v-btn small
          color="primary"
          style="min-width: auto; position: absolute; right: 50px; height: 25px"
          dark
          class="px-1 py-1"
          v-if="filterActivated"
          @click.prevent="
            filterActivated = false
            cleanFilters()
            getAssignedEvaluations()
          "
        >
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              style="min-width: auto; position: absolute; right: 10px; height: 25px"
              dark
              class="px-1 py-1"
              @click.prevent="
                filterActivated = true
                getAssignedEvaluations()
              "
              ><v-icon dark> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
      </v-form>
    </div>

    <simple-list
      :title="''"
      :dense="true"
      :headers="headers"
      :items="items"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      :loadingText="loadingText"
      :itemKey="'id'"
      :hideFooter="true"
      :hideHeader="false"
      :multiSort="false"
      :loading="loading"
      :class="'my-2 pb-2'"
      :sortable="false"
      :page="page"
      :pages="pages"
      :extraButtoms="extraButtoms"
      :showCreate="false"
      :showEdit="franchiseDisabled ? false : true"
      :showDelete="franchiseDisabled ? false : true"
      :buttonLink="'empresas/ficha'"
      :orderOptions="orderOptions"
      :orderBy="orderBy"
      @edit:item="handleEdit($event)"
      @delete:item="deleteItem($event)"
      @update:page="pagination($event)"
      @orderEvent="handleOrder($event)"
      @showPositionProfile="handleProfileRedirect($event)"
      @handleAssignationDetails="handleAssignationDetails($event)" />
    <v-dialog v-model="crudModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card style="padding: 10px 1rem">
        <h2 class="text-center mb-4" v-if="flow === 'edit'">
          Ver Asignación: {{ item.evaluation.name + ' #' + item.id }}
        </h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de asignación: {{ toLocaleDateString(date, 'es-VE') }}.
          {{ dateRangeText }}
          {{ Math.abs(dateRange) > 0 ? Math.abs(dateRange) + ' días' : '' }}
        </span>

        <v-form
          ref="formCompany"
          v-model="valid"
          style="width: 100%"
          :disabled="item.status === 3"
        >
          <v-row class="mx-0 mt-4" v-if="flow === 'edit'">
            <v-col cols="12" lg="4" md="4" sm="6">
              <v-row no-gutters>
                <span style="width: 35%">Empresa: </span>
                <multiselect
                  style="width: 65%"
                  track-by="id"
                  label="name"
                  disabled
                  deselect-label="Remover"
                  select-label="Seleccionar"
                  placeholder="Seleccionar"
                  v-model="item.company"
                  :options="companies"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                label="Observación de la evaluación"
                v-model="observation"
                :readonly="item.status === 1 && item.status === 2"
                persistent-hint
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.required]"
                label="Fecha"
                :readonly="item.status === 1 && item.status === 2"
                v-model="date"
                type="date"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12" v-if="item.status === 4">
              <v-checkbox label="Marcar como revisada" v-model="revised"
            /></v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-col cols="12" style="height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid || item.status === 3"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
            :disabled="item.status === 3"
          >
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="crudModal = false"
            :disabled="item.status === 3"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </v-dialog>
    <mass-assignment-evaluations
      :activeModal="activeModal"
      @closeModal="activeModal = false"
      @update:active="refreshActiveModal($event)"
      @refresh="getAssignedEvaluations()"
  /></v-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import MassAssignmentEvaluations from '@/components/SupervisionControl/MassAssignmentEvaluations.vue'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'
  import Help from '@/components/App/Help'

  export default {
    name: 'ListAssignedEvaluations',
    components: {
      SimpleList,
      MassAssignmentEvaluations,
      Help,
    },
    data() {
      return {
        tab: 'tab1',
        loading: true,
        menu: false,
        crudModal: false,
        activeModal: false,
        evaluation: {},
        headers: [
          {
            text: '#',
            value: 'id',
          },
          { text: 'Evaluación', value: 'evaluation.name' },
          { text: 'Empresa', value: 'company.name', align: ' font-weight-bold' },

          { text: 'Estatus', value: 'statusText' },
          { text: 'Fecha de Inicio', value: 'dateText' },
          { text: 'Periodicidad', value: 'periodicityText' },
          { text: 'Acciones', value: 'actions', align: 'left', width: '120px' },
        ],
        items: [],
        companies: [],
        evaluations: [],
        users: [],
        user: null,
        revised: false,
        dataAssignations: [],
        navigationElements: [
          {
            text: 'Control y Supervisión',
            disabled: false,
            query: null,
            params: null,
            name: 'business/supervision-control/Dashboard',
            href: '/business/supervision-control/admin-panel',
          },
          {
            text: 'Evaluaciones asignadas',
            disabled: true,
            query: null,
            params: null,
          },
        ],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        conditionOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Aprobada' },
          { id: 0, name: 'Reprobada' },
        ],
        filters: {
          search: '',
          company: null,
          evaluation: null,
          user: null,
          periodicity: null,
          status: null,
          startAt: '',
          endAt: '',
          condition: { id: null, name: 'Todas' },
          // startAt: new Date(new Date().setMonth(new Date().getMonth() - 1))
          //   .toISOString()
          //   .split('T')[0],
          // endAt: new Date(new Date().setMonth(new Date().getMonth() + 1))
          //   .toISOString()
          //   .split('T')[0],
        },
        itemsPeriodicity: [
          { id: 0, name: 'Diario' },
          { id: 1, name: 'Semanal' },
          { id: 2, name: 'Quincenal' },
          { id: 3, name: 'Mensual' },
          { id: 4, name: 'Trimestral' },
          { id: 5, name: 'Semestral' },
          { id: 6, name: 'Anual' },
        ],
        statusOptions: [
          { id: 0, name: 'Asignada' },
          { id: 1, name: 'En curso' },
          { id: 2, name: 'En revisión' },
          { id: 3, name: 'Completada' },
        ],
        extraButtoms: [
          {
            text: 'Detalle de la asignación',
            icon: 'mdi-file-search',
            event: { active: true, name: 'handleAssignationDetails' },
          },
        ],
        assignedTabs: 0,
        company: null,
        observation: '',
        status: 0,
        date: '',
        periodicity: '',
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        loadingText: 'Cargando registros',
        valid: true,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        rules: {
          requiredAssigned: (v) => v !== '' || 'Requerido.',
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) => v.length <= 400 || 'Debe poser menos de 400 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    created() {
      // this.company = this.currentCompany
      // this.filters.company = this.currentCompany
      // this.getEvaluation()
      this.getAssignedEvaluations()
      this.getEvaluations()
      this.getCompanies()
      this.getUsers()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      currentCompany: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },

      orderOptions() {
        return [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Fecha de Inicio', order: 'date', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ]
      },
      dateRangeText() {
        if (this.dateRange !== '') {
          if (this.dateRange > 0) {
            return 'Dentro de '
          } else if (this.dateRange === 0) {
            return 'Hoy'
          } else if (this.dateRange < 0) {
            return 'Hace '
          }
          return ''
        } else {
          return ''
        }
      },
      franchiseDisabled() {
        if (
          this.$route.name === 'business/supervision-control/company/AssignedEvaluations'
        ) {
          return true
        } else {
          return false
        }
      },
    },
    watch: {
      filters: {
        handler(newValue) {
          if (newValue.condition.id === 0 || newValue.condition.id === 1) {
            newValue.status = this.statusOptions.find((option) => option.id === 3)
          }
        },
        deep: true,
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      fullName({ name, lastname, email }) {
        if (!lastname && name) return name
        if (!email && lastname && name) return `${name} ${lastname}`
        return `${name} ${lastname} | ${email}`
      },

      handleAssignationDetails(assignedEvaluation) {
        this.$router.push({
          name: 'business/supervision-control/AssignedEvaluationDetails',
          params: {
            assignedEvaluationId: assignedEvaluation.id,
          },
        })
      },
      refreshActiveModal(event) {
        this.activeModal = event
      },
      handleCreate() {
        this.flow = 'create'
        this.activeModal = true
      },

      onlyNumber($event) {
        const keyCode = $event.keyCode ? $event.keyCode : $event.which
        if (keyCode < 48 || keyCode > 57) {
          $event.preventDefault()
        }
      },

      getEvaluation() {
        if (this.$route.params.evaluationId && this.$route.params.evaluationId > 0) {
          this.navigationElements[1].query = {
            evaluationId: this.$route.params.evaluationId,
          }
          this.$axios
            .get('evaluations/' + this.$route.params.evaluationId)
            .then((response) => {
              this.evaluation = response.data
            })
        } else {
          this.$router.go(-1)
        }
      },

      cleanFilters() {
        this.filters = {
          search: '',
          company: null,
          evaluation: null,
          user: null,
          periodicity: null,
          status: null,
          startAt: '',
          endAt: '',
          condition: { id: 0, name: 'Aprobada o Reprobada' },
        }
      },

      async getCompanies(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get(
            'companies?byHierarchy=1&order=name&by=asc&limit=50&company_id=' +
              this.currentCompany.id +
              searchStr
          )
          .then((response) => {
            this.companies = response.data
          })
      },

      async getEvaluations(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('evaluations?evaluation_id=0&order=name&by=asc&limit=50' + searchStr)
          .then((response) => {
            this.evaluations = response.data
          })
      },

      async getUsers(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('users?role=auditor_evaluations&order=name&by=asc&limit=50' + searchStr)
          .then((response) => {
            this.users = response.data
          })
      },

      async getAssignedEvaluations() {
        this.loading = true
        this.activeModal = false
        let queryParams = ''
        let orderString = ''
        let companyString = ''
        let searchString = ''
        let evaluationString = ''
        let userString = ''
        let periodicityString = ''
        let statusString = ''
        let startString = ''
        let endString = ''
        let companyFilterString = ''
        let conditionString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.currentCompany && this.currentCompany.id) {
          companyString = '&company_id=' + this.currentCompany.id
        }
        if (this.filterActivated) {
          if (this.filters.company && this.filters.company.id) {
            companyFilterString = '&companyFilter=' + this.filters.company.id
          }
          if (this.filters.startAt) {
            startString = '&startAt=' + this.filters.startAt
          }
          if (this.filters.endAt) {
            endString = '&endAt=' + this.filters.endAt
          }
          if (this.filters.condition.id === 0) {
            conditionString = '&failed=1'
          } else if (this.filters.condition.id === 1) {
            conditionString = '&passed=1'
          }
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.evaluation && this.filters.evaluation.id) {
            evaluationString = '&evaluation_id=' + this.filters.evaluation.id
          }
          if (this.filters.user && this.filters.user.id) {
            userString = '&user_id=' + this.filters.user.id
          }
          if (this.filters.status && this.filters.status.id !== null) {
            statusString = '&status=' + this.filters.status.id
          }
          if (this.filters.periodicity && this.filters.periodicity.id) {
            periodicityString = '&periodicity=' + this.filters.periodicity.id
          }
        }
        queryParams =
          searchString +
          endString +
          startString +
          evaluationString +
          userString +
          statusString +
          periodicityString +
          companyFilterString +
          conditionString
        this.$axios
          .get(
            'assigned-evaluations?page=' +
              this.page +
              orderString +
              queryParams +
              companyString
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items && this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.items.map((x) => {
              x.showEdit = true
              x.showDelete = true
              if (x.status === 3) {
                x.showEdit = false
                x.showDelete = false
              }
              return x
            })

            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((assigned) => {
              switch (assigned.status) {
                case 0:
                  assigned.statusText = 'Asignada'
                  break
                case 1:
                  assigned.statusText = 'En curso'
                  break
                case 2:
                  assigned.statusText = 'En revisión'
                  break
                case 3:
                  assigned.statusText = 'Completada'
                  break
                default:
                  assigned.statusText = ''
                  break
              }
              switch (assigned.periodicity) {
                case 0:
                  assigned.periodicityText = 'Diario'
                  break
                case 1:
                  assigned.periodicityText = 'Semanal'
                  break
                case 2:
                  assigned.periodicityText = 'Quincenal'
                  break
                case 3:
                  assigned.periodicityText = 'Mensual'
                  break
                case 4:
                  assigned.periodicityText = 'Trimestral'
                  break
                case 5:
                  assigned.periodicityText = 'Semestral '
                  break
                case 6:
                  assigned.periodicityText = 'Anual'
                  break

                default:
                  assigned.periodicityText = ''
                  break
              }
              assigned.dateText = toLocaleDateString(assigned.date)
            })
            this.loading = false
          })
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getAssignedEvaluations()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.observation = this.item.observation
        this.status = this.item.status
        this.periodicity = this.item.periodicity
        this.company = this.item.company
        this.date = this.item.date.split(' ')[0]
        this.dataAssignations = []
        this.createdAt = new Date(this.item.date)
        this.dateRange = Math.ceil((this.createdAt - this.toDay) / (1000 * 60 * 60 * 24))

        this.crudModal = true
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async saveItem() {
        this.$axios
          .patch('assigned-evaluations/' + this.item.id, {
            evaluation_id: this.evaluation.id,
            observation: this.observation,
            status: this.revised ? 5 : this.status,
            date: this.date,
          })
          .then(() => {
            this.page = 1
            this.getAssignedEvaluations()
            this.crudModal = false
          })
      },

      async deleteItem(item) {
        this.$axios.delete('assigned-evaluations/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getAssignedEvaluations()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getAssignedEvaluations()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .v-dialog {
    background-color: white;
  }
  .v-tabs-slider {
    margin-top: -5px;
  }
</style>
