<template>
  <div class="vuse-content-wrapper">
    <smart-breadcrumbs :items="navigationElements" />
    <!-- Content -->
    <v-card>
      <v-toolbar color="primary" dark height="50" class="p-0">
        <v-tabs v-model="tab" centered slider-color="teal" class="m-0">
          <v-tab href="#tab1"> Evaluaciones asignadas </v-tab>
          <v-tab href="#tab2"> Cronograma de evaluaciones </v-tab>
        </v-tabs>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab1">
          <list-assigned-evaluations />
        </v-tab-item>
        <v-tab-item value="tab2">
          <v-card flat>
            <calendar-assigned-evaluations />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
  import CalendarAssignedEvaluations from '@/components/SupervisionControl/CalendarAssignedEvaluations'
  import ListAssignedEvaluations from '@/components/SupervisionControl/ListAssignedEvaluations'

  export default {
    name: 'GlobalAssignedEvaluations',
    components: {
      CalendarAssignedEvaluations,
      ListAssignedEvaluations,
    },
    data() {
      return {
        tab: 'tab1',
        navigationElements: [
          {
            text: 'Control y Supervisión',
            disabled: false,
            query: null,
            params: null,
            name: 'business/supervision-control/Dashboard',
            href: '/business/supervision-control/admin-panel',
          },
          {
            text: 'Evaluaciones Asignadas',
            disabled: true,
          },
        ],
      }
    },
  }
</script>
<style lang="scss">
  .v-tabs-slider {
    margin-top: -5px;
  }
</style>
