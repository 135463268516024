<template>
  <v-sheet class="evaluations-calendar neu-glow-inset" style="width: 100%">
    <v-row class="mx-0" align="center" justify="space-between">
      <v-card-title>
        <help class="help--text-left" :helpId="31" :xSmall="true" />Cronograma de
        evaluaciones</v-card-title
      ><v-spacer />
      <div class="d-flex align-center">
        <v-btn icon class="ma-1" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn icon class="ma-1" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-row justify="center" align="center" class="mx-0">
      <v-col cols="12" sm="6">
        <v-select
          v-model="status"
          :items="availableStatus"
          :disabled="franchiseDisabled"
          dense
          outlined
          hide-details
          label="Status"
          class="ma-2"
          @change="index++"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          v-model="type"
          :items="types"
          dense
          outlined
          hide-details
          class="ma-2"
          label="Periodo"
      /></v-col>
    </v-row>
    <v-sheet height="600" class="px-2 pb-2" color="transparent">
      <v-calendar
        :key="index"
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        show-month-on-first
        @change="updateRange"
        :locale="locale"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
      />
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
        max-width="500"
      >
        <v-card :elevation="0" max-width="600px">
          <v-toolbar :color="getEventColor(selectedEvent)" dark>
            <v-toolbar-title v-html="selectedEvent.name + ' #' + selectedEvent.id" />
            <v-spacer />
            <!-- <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn> -->
          </v-toolbar>
          <v-card-text>
            <p
              v-if="
                selectedEvent.user &&
                selectedEvent.user.name != '' &&
                selectedEvent.user.lastname != ''
              "
            >
              <strong> Evaluador: </strong>

              {{ selectedEvent.user.name + ' ' + selectedEvent.user.lastname }}
            </p>
            <p v-if="selectedEvent.company && selectedEvent.company.name != ''">
              <strong> Empresa: </strong> {{ selectedEvent.company.name }}
            </p>
            <p>
              <strong> Asignada el: </strong>
              {{ toLocaleDateString(selectedEvent.date) }}
            </p>
            <p v-if="selectedEvent.status === 3 && selectedEvent.effective_end_date">
              <strong> Completada el: </strong>

              {{ toLocaleDateString(selectedEvent.effective_end_date.split(' ')[0]) }}
            </p>
            <v-btn x-small rounded dark :color="selectedEvent.statusColor">{{
              selectedEvent.statusName
            }}</v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              @click="
                $router.push({
                  name: 'business/supervision-control/AssignedEvaluationDetails',
                  params: { assignedEvaluationId: selectedEvent.id },
                })
              "
            >
              {{ selectedEvent.status === 0 ? 'Evaluar' : 'Ver' }} </v-btn
            ><v-spacer />
            <v-btn text color="primary" @click="selectedOpen = false"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </v-sheet>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'
  import EvaluationStatus from '@/data/SupervisionControl/evaluationStatus'
  export default {
    data: () => ({
      focus: '',
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      modes: ['stack', 'column'],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      status: 0,
      availableStatus: [
        { text: 'Asignadas', value: 0 },
        { text: 'En curso', value: 1 },
        { text: 'En revisión', value: 2 },
        { text: 'Completadas', value: 3 },
      ],
      typeToLabel: {
        month: 'Mes',
        week: 'Semena',
        day: 'Día',
      },
      mode: 'stack',
      value: '',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        '#003a56',
        'grey darken-1',
        'cyan',
        'indigo',
        'deep-purple',
        'orange',
        'green',
        'browm',
        'dark',
        'purple',
        'black lighten-4',
        'grey darken-3',
      ],
      index: 0,
    }),
    mounted() {},
    created() {
      this.franchiseDisabledStatus()
    },
    computed: {
      ...mapGetters(['locale', 'userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set() {},
      },
      franchiseDisabled() {
        if (
          this.$route.name === 'business/supervision-control/company/AssignedEvaluations'
        ) {
          return true
        } else {
          return false
        }
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      viewDay({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor(event) {
        return event.color
      },
      setToday() {
        this.focus = ''
      },
      prev() {
        this.$refs.calendar.prev()
      },
      next() {
        this.$refs.calendar.next()
      },
      getStatusData(evaluation) {
        if (evaluation.status === 0) {
          evaluation.statusName = EvaluationStatus.find((status) => status.id === 0).name
          evaluation.statusColor = EvaluationStatus.find(
            (status) => status.id === 0
          ).color
        } else if (evaluation.status === 1) {
          evaluation.statusName = EvaluationStatus.find((status) => status.id === 1).name
          evaluation.statusColor = EvaluationStatus.find(
            (status) => status.id === 1
          ).color
        } else if (evaluation.status === 2) {
          evaluation.statusName = EvaluationStatus.find((status) => status.id === 2).name
          evaluation.statusColor = EvaluationStatus.find(
            (status) => status.id === 2
          ).color
        } else if (
          evaluation.status === 3 &&
          evaluation.passed === false &&
          evaluation.failed === false
        ) {
          evaluation.statusName = EvaluationStatus.find(
            (status) =>
              status.id === 3 && status.passed === false && status.failed === false
          ).name
          evaluation.statusColor = EvaluationStatus.find(
            (status) =>
              status.id === 3 && status.passed === false && status.failed === false
          ).color
        } else if (evaluation.status === 3 && evaluation.passed === true) {
          evaluation.statusName = EvaluationStatus.find(
            (status) => status.id === 3 && status.passed === true
          ).name
          evaluation.statusColor = EvaluationStatus.find(
            (status) => status.id === 3 && status.passed === true
          ).color
        } else if (evaluation.status === 3 && evaluation.failed === true) {
          evaluation.statusName = EvaluationStatus.find(
            (status) => status.id === 3 && status.failed === true
          ).name
          evaluation.statusColor = EvaluationStatus.find(
            (status) => status.id === 3 && status.failed === true
          ).color
        } else {
          evaluation.statusName = ''
          evaluation.statusColor = EvaluationStatus.find(
            (status) => status.id === 0
          ).color
        }
      },
      showEvent({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          )
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      updateRange({ start, end }) {
        this.evaluations = []
        const queryParams =
          '?limit=100&startAt=' +
          start.date +
          '&endAt=' +
          end.date +
          '&status=' +
          this.status +
          '&company_id=' +
          this.company.id
        this.$axios.get('assigned-evaluations' + queryParams).then((response) => {
          let events = []
          let index = 0
          const matrixColors = [{ name: null, color: null }]
          events = response.data.filter((assign) => assign.evaluation)

          events.forEach((evaluation) => {
            const aux = matrixColors.find(
              (assign) => assign.name === evaluation.evaluation.name
            )
            if (aux === undefined) {
              if (index > this.colors.length) index = 0
              matrixColors.push({
                name: evaluation.evaluation.name,
                color: this.colors[index],
              })
              index++
            }
          })

          events.forEach((evaluation) => {
            const aux = matrixColors.find(
              (assign) => assign.name === evaluation.evaluation.name
            )
            evaluation.name = evaluation.evaluation.name
            evaluation.start = new Date(evaluation.date)
            evaluation.end = new Date(evaluation.estimated_end_date)
            evaluation.color = aux.color
            evaluation.timed = false

            this.getStatusData(evaluation)
          })

          this.events = events
        })
      },
      franchiseDisabledStatus() {
        if (this.franchiseDisabled) {
          this.status = 3
        }
      },
    },
  }
</script>
<style lang="scss">
  .evaluations-calendar {
    .v-calendar .v-event {
      font-size: 10px;
      margin: 1px 2px;
      width: calc(100% - 3px) !important;
    }
    .v-present {
      .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #cdcdcd;
      }
    }
  }
</style>
