<template>
  <v-dialog v-model="crudModal" :height="'80%'" :width="'80%'" :adaptive="true">
    <v-card style="padding: 10px 1rem">
      <help class="help--card" :helpId="30" />
      <h2 class="text-center mb-4">Asignar Evaluación</h2>
      <v-form ref="formAssignation" v-model="valid" style="width: 100%">
        <v-row class="mx-0">
          <v-col cols="12" md="6">
            <v-row class="mx-0">
              <label for="" style="width: 25%">Asignar a:</label>
              <multiselect
                ref="multiselect"
                required
                style="width: 75%"
                track-by="id"
                label="name"
                deselect-label="Remover selección"
                select-label="Seleccionar"
                placeholder="Seleccionar"
                v-model="company"
                :options="companies"
                :multiple="false"
                :allow-empty="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                @search-change="getCompanies"
              />
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="mx-0">
              <label for="" style="width: 30%">Evaluación:</label>
              <multiselect
                ref="multiselect"
                required
                style="width: 70%"
                track-by="id"
                label="name"
                deselect-label="Remover selección"
                select-label="Seleccionar"
                placeholder="Seleccionar"
                v-model="evaluation"
                :options="evaluations"
                :multiple="false"
                :allow-empty="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                @search-change="getEvaluations"
              />
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="mx-0">
              <label for="" style="width: 25%">Evaluador:</label>
              <multiselect
                required
                style="width: 75%"
                track-by="id"
                label="name"
                :custom-label="fullName"
                deselect-label="Remover selección"
                select-label="Seleccionar"
                placeholder="Seleccionar"
                v-model="user"
                :options="users"
                :multiple="false"
                :allow-empty="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                @search-change="getUsers"
              />
            </v-row>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-select
              clearable
              dense
              :rules="[rules.requiredAssigned]"
              v-model="periodicity"
              :items="itemsPeriodicity"
              item-text="name"
              item-value="id"
              label="Periodicidad"
              single-line
            />
            <span v-if="periodicity" class="caption text--secondary">
              Esta evaluación se aplica
              {{ periodicity | toPeriodicityText }}</span
            >
          </v-col>
          <v-col cols="12" md="6" v-if="valid">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="metricRange"
                  :rules="[rules.required]"
                  label="Rango de tiempo global"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :hint="
                    periodicity && periodicity !== ''
                      ? `Para asignaciones multiples, seleccione un intérvalo superior o igual a ${intervalText}`
                      : ''
                  "
                  :persistent-hint="true"
                />
              </template>
              <v-date-picker
                range
                :rules="[rules.required]"
                v-model="metricRange"
                clearable
                :selected-items-text="metricRange | toRangeText"
                min="2000-01-01"
                @change="handlePeriodicity()"
              />
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">
              <span v-if="dataAssignations.length > 0" class="mb-5"
                >Ha generado {{ dataAssignations.length }} auditorias en el intervalo
                seleccionado</span
              >
              <v-expansion-panels accordion multiple focusable v-model="expPanel">
                <v-expansion-panel v-for="tab in dataAssignations" :key="tab.id">
                  <v-expansion-panel-header
                    ><h4>{{ tab.title }}</h4>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          dark
                          small
                          fab
                          icon
                          elevation="3"
                          absolute
                          style="right: 5%"
                          @click.prevent="deleteDataMetric(tab)"
                          ><v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar este intérvalo de asignación</span>
                    </v-tooltip>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="mt-4">
                    <v-row class="mx-0">
                      <v-col cols="12" lg="4" md="6" sm="12">
                        <v-text-field
                          label="Observación"
                          v-model="tab.observation"
                          persistent-hint
                          :counter="400"
                          :hint="'Este texto puede usarse como indicaciones al momento de realizar la auditoría y va dirigido al evaluador'"
                        />
                      </v-col>
                      <v-col cols="12" lg="4" md="6" sm="12">
                        <v-text-field
                          :rules="[rules.required]"
                          label="Fecha"
                          v-model="tab.date"
                          type="date"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-col cols="12" style="height: 10%">
      <div class="row">
        <v-btn small
          style="margin: auto; margin-top: 10px"
          :disabled="!valid"
          color="primary"
          @click="createItem()"
        >
          Asignar
        </v-btn>
        <v-btn
          small
          style="margin: auto; margin-top: 10px"
          color="primary"
          @click="reset"
        >
          Limpiar
        </v-btn>
        <v-btn small
          style="margin: auto; margin-top: 10px"
          color="primary"
          @click="$emit('closeModal')"
        >
          Cancelar
        </v-btn>
      </div>
    </v-col>
  </v-dialog>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'
  export default {
    name: 'MassAssignmentEvaluations',
    data() {
      return {
        loading: true,
        menu: false,
        crudModal: false,
        evaluation: null,
        company: null,
        user: null,
        evaluations: [],
        companies: [],
        users: [],
        dataAssignations: [],
        metricRange: [],
        item: {},
        itemsPeriodicity: [
          { id: 0, name: 'Diario' },
          { id: 1, name: 'Semanal' },
          { id: 2, name: 'Quincenal' },
          { id: 3, name: 'Mensual' },
          { id: 4, name: 'Trimestral' },
          { id: 5, name: 'Semestral' },
          { id: 6, name: 'Anual' },
        ],
        assignedTabs: 0,
        observation: '',
        status: 0,
        date: '',
        periodicity: '',
        valid: true,
        rules: {
          requiredAssigned: (v) => v !== '' || 'Requerido.',
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) => v.length <= 400 || 'Debe poser menos de 400 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    created() {
      this.company = this.currentCompany
      this.getCompanies()
      this.getUsers()
      this.getEvaluations()
    },
    watch: {
      company() {
        this.metricRange = []
      },
      evaluation() {
        this.metricRange = []
      },
      user() {
        this.metricRange = []
      },
      periodicity() {
        this.metricRange = []
      },
      assignation(newValue) {
        this.item = newValue
      },
      crudModal(newValue) {
        this.$emit('update:active', newValue)
      },
      activeModal(newValue) {
        this.crudModal = newValue
      },
    },
    props: {
      activeModal: {
        type: Boolean,
        default: false,
      },
      assignation: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      currentCompany: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      maxValue() {
        switch (this.evaluation.type) {
          case 2:
            return 5
          default:
            return 10
        }
      },
      intervalText() {
        if (!this.periodicity) return '*'
        switch (this.periodicity) {
          case 0: // Diario
            return 'un día'
          case 1: // Semanal
            return 'una semana'
          case 2: // Quincenal
            return 'una quincena'
          case 3: // Mensual
            return 'un mes'
          case 4: // Trimestral
            return 'un trimestre'
          case 5: // Semestral
            return 'un semestre'
          case 6: // Anual
            return 'un año'
          default:
            return ''
        }
      },
      expPanel() {
        if (this.assignedTabs && typeof this.assignedTabs === 'number') {
          return [...Array(this.assignedTabs).keys()].map((k, i) => i)
        } else {
          return []
        }
      },
    },
    filters: {
      toPeriodicityText(periodicity) {
        if (periodicity === null) return '*'
        switch (periodicity) {
          case 0: // Diario
            return 'diariamente'
          case 1: // Semanal
            return 'semanalmente'
          case 2: // Quincenal
            return 'quincenalmente'
          case 3: // Mensual
            return 'mensualmente'
          case 4: // Trimestral
            return 'trimestralmente'
          case 5: // Semestral
            return 'Semestralmente'
          case 6: // Anual
            return 'anualmente'
          default:
            return ''
        }
      },
      toRangeText(range) {
        if (!range) return ''
        return range.join(' ~ ')
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      async handleCreate() {
        this.item = {}
        this.observation = ''
        this.status = 0
        this.company = this.currentCompany
        this.assignedTabs = 0
        this.periodicity = ''
        this.dataAssignations = []

        this.crudModal = true
      },
      async getCompanies(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get(
            'companies?byHierarchy=1&order=name&by=asc&limit=50&company_id=' +
              this.currentCompany.id +
              searchStr
          )
          .then((response) => {
            this.companies = response.data
          })
      },

      async getUsers(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('users?role=auditor_evaluations&order=name&by=asc&limit=100' + searchStr)
          .then((response) => {
            this.users = response.data
          })
      },

      async getEvaluations(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('evaluations?evaluation_id=0&limit=50&order=name&by=asc' + searchStr)
          .then((response) => {
            this.evaluations = response.data
          })
      },

      fullName({ name, lastname }) {
        if (!lastname && name) return name
        return `${name} ${lastname}`
      },

      handlePeriodicity() {
        if (!this.user || !this.user.id) {
          this.$swal({
            title: '',
            text: 'Selecciona un evaluador',
            icon: 'info',
          })
          this.menu = false
          this.metricRange = []
          this.dataAssignations = []
        } else if (!this.company || !this.company.id) {
          this.$swal({
            title: '',
            text: 'Selecciona una empresa',
            icon: 'info',
          })
          this.menu = false
          this.metricRange = []
          this.dataAssignations = []
        } else if (!this.evaluation || !this.evaluation.id) {
          this.$swal({
            title: '',
            text: 'Selecciona una evaluación',
            icon: 'info',
          })
          this.menu = false
          this.metricRange = []
          this.dataAssignations = []
        } else {
          this.menu = false
          const startAt = new Date(this.metricRange[0])
          const endAt = new Date(this.metricRange[1])
          const selectedDays = Math.abs((endAt - startAt) / (1000 * 3600 * 24)) + 1
          this.dataAssignations = []
          switch (this.periodicity) {
            case 0: // Diario
              this.calculateIntervals(startAt, selectedDays, 1)
              break
            case 1: // Semanal
              this.calculateIntervals(startAt, selectedDays, 7)
              break
            case 2: // Quincenal
              this.calculateIntervals(startAt, selectedDays, 15)
              break
            case 3: // Mensual
              this.calculateIntervals(startAt, selectedDays, 30)
              break
            case 4: // Trimestral
              this.calculateIntervals(startAt, selectedDays, 90)
              break
            case 5: // Semestral
              this.calculateIntervals(startAt, selectedDays, 180)
              break
            case 6: // Anual
              this.calculateIntervals(startAt, selectedDays, 365)
              break
            default:
              break
          }
        }
      },

      calculateIntervals(startAt, selectedDays, intervalDays) {
        this.assignedTabs = parseInt(selectedDays / intervalDays)
        let start = new Date(
          startAt.getFullYear(),
          startAt.getMonth(),
          startAt.getDate() + 1
        )

        for (let index = 1; index <= this.assignedTabs; index++) {
          this.dataAssignations.push({
            id: index,
            title: toLocaleDateString(start, 'es-VE'),
            company_id: this.company.id,
            evaluation_id: this.evaluation.id,
            user_id: this.user.id,
            observation: null,
            status: 0,
            periodicity: this.periodicity,
            menu: false,
            date: new Date(start).toISOString().split('T')[0],
          })
          start = new Date(start).setDate(new Date(start).getDate() + intervalDays)
        }
      },
      deleteDataMetric(tab) {
        this.dataAssignations = this.dataAssignations.filter(
          (metric) => metric.id !== tab.id
        )
      },

      validate() {
        this.$refs.formCompany.validate()
      },
      async createItem() {
        if (!this.user || !this.user.id) {
          this.$swal({
            title: '',
            text: 'Selecciona un usuario',
            icon: 'info',
          })
        }
        if (!this.company || !this.company.id) {
          this.$swal({
            title: '',
            text: 'Selecciona una empresa',
            icon: 'info',
          })
        }
        if (this.dataAssignations.length > 0) {
          this.crudModal = false

          this.dataAssignations.forEach((assignation) => {
            const sum = this.evaluation.duration > 1 ? this.evaluation.duration : 0
            const date = new Date(assignation.date)
            const end = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() + sum
            )
              .toISOString()
              .split('T')[0]
            assignation.estimated_end_date = end
          })
          this.$axios
            .post('assigned-evaluation-mass-create', {
              assignedEvaluation: this.dataAssignations,
            })
            .then(() => {
              this.$emit('refresh')
            })
        }
      },
      reset() {
        this.$refs.formAssignation.reset()
        this.$refs.formAssignation.resetValidation()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
